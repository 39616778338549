.img-takeaway-logo {
	position: absolute;
	top: 5%;
	left: 14%;
	background-color: white;
	height: 16px;
	padding: 2px;
	border-radius: 4px;
}

.amount-footer {
	padding: 10px;
	bottom: 50px;
	@media (orientation: portrait) {
	}
}

.amount-footer-child {
	margin-bottom: 7px;
}

.amount-line {
	color: #fff;
	box-shadow: none;
	width: 100%;
	margin-bottom: 0;
	margin-top: 5px;
	border-width: 0;
	height: 1px;
	background-color: #ada6a6;
}
