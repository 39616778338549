.container {
	position: relative;
	text-align: center;
	color: white;
}

.img-sold-out-text {
	color: red;
	font-weight: 900;
	position: absolute;
	top: 55%;
	left: 40%;
	transform: translate(-50%, -50%);
}
.img-promo-text {
	color: #ff9800;
	background-color: white;
	width: 75%;
	font-weight: 700;
	position: absolute;
	top: 15px;
	left: -13px;
	font-size: 8px;
	transform: rotate(-45deg);
	border-radius: 8px 8px 0px 0px;
}

.order-button {
	font-size: 40px;
	position: relative;
	bottom: 0px;
}

.order-add {
	color: #ff9800;
	margin-top: 20px;
	@extend .order-button;
}

.order-minus {
	color: #959595;
	margin-top: 20px;
	@extend .order-button;
}

.weight-input {
	.MuiInputBase-input {
		text-align: end;
		padding: 2px;
	}
}

.quantity-input {
	.MuiInputBase-input {
		text-align: center;
		padding: 2px;
		font-size: 35px;
		font-family: 'Poppins', sans-serif !important;
	}
}

.line-description {
	color: #f1f1f1;
	background-color: #f1f1f1;
	height: 2px;
	border-width: 0;
}

.orig-padding {
	padding: 8px 12px 12px;
}

.custom-label {
	background-color: #e2e2e2;
	color: black;
	width: fit-content;
	padding: 15px 13px 15px 13px;
	border-radius: 3px;
}

.custom-label-checked {
	background-color: #b1aeae;
	color: black;
	width: fit-content;
	padding: 15px 13px 15px 13px;
	border-radius: 3px;
}

.option-radio {
	.MuiFormGroup-root {
		display: inherit;
	}
}
