.list-takeaway-logo {
	position: absolute;
	top: 15%;
	margin-left: 4px;
	background-color: white;
	height: 16px;
	padding: 2px;
	border-radius: 4px;
}

.order-button {
	font-size: 40px;
	position: relative;
	bottom: 0px;
}

.order-add {
	color: #ff9800;
	margin-top: 20px;
	@extend .order-button;
}

.order-minus {
	color: #959595;
	margin-top: 20px;
	@extend .order-button;
}

.void-quantity {
	.MuiInputBase-input {
		text-align: center;
		padding: 2px;
		font-size: 25px;
		font-family: 'Poppins', sans-serif !important;
		height: 30px;
	}
}

.confirm-footer {
	position: fixed;
	bottom: 0px;
	@media (orientation: portrait) {
	}
}

.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	visibility: hidden;
	background-color: #faf2e8;
	color: black;
	text-align: center;
	border: 1px solid #faf2e8;
	padding: 5px;
	font-size: 10px;
	white-space: nowrap;
	border-radius: 7px;
	position: absolute;
	z-index: 1;
	top: -45%;
	left: 38%;
	margin-left: -20px;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}
